import React from 'react';
import styled from 'styled-components';
import { ui } from '../../../core';
import PageLayout from '../../components/layout/PageLayout';
import Header from './components/HeaderView';
import Spacer from '../../components/other/Spacer';
import ReliBirdsView from './components/ReliBirdsView';
import MemReliView from './components/MemReliView';

// Assets
import HeaderBackgroundImg from '../../../assets/app/reli_background.png';

const ReliBirdsScreen: React.FC = () => (
  <PageLayout meta={{ title: 'New Relic' }}>
    <Container>
      <Header />
      <HeaderBackground src={HeaderBackgroundImg} alt="HeaderBackground" />
      <Spacer height={50} />
      <ReliBirdsView />
      <Spacer height={300} />
      <MemReliView />
      {/* <Spacer height={350} /> */}
    </Container>
  </PageLayout>
);

export default ReliBirdsScreen;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: ${ui.NAVBAR_HEIGHT}px;

  width: 100%;
`;

const HeaderBackground = styled.img`
  position: absolute;
  top: 300px;

  max-width: ${ui.MAX_WIDTH}px;
  width: 90vw;

  opacity: 1;
`;
