import { globalBind, shared } from '@agile-ts/core';
import reactIntegration from '@agile-ts/react';

import * as relibirds from './entities/relibirds';
import * as memreli from './entities/memreli';
import * as games from './entities/games';
import * as ui from './entities/ui';
import * as user from './entities/user';

shared.integrate(reactIntegration);

export const core = {
  relibirds,
  memreli,
  games,
  ui,
  user,
};

// For better debugging
if (process.env.NODE_ENV !== 'production') globalBind('__core__', core);

export default core;

export { relibirds, memreli, games, ui, user };
