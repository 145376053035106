import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useAgile } from '@agile-ts/react';
import { relibirds, ui } from '../../../../../../../core';
import LinesBackground from '../../../../../../components/primitive/background/LinesBackground';
import ReliBirds from '../../../../../../components/games/ReliBirds';
import LabelText from '../../../../../../components/primitive/text/LabelText';
import { Button } from '../../../../../../components/primitive';
import Icon from '../../../../../../components/icons';
import { useEventTracker } from '../../../../../../hooks/useEventTracker';
import { useTheme } from '../../../../../../theme/useTheme';
import { GAME_STATUS } from '../../../../../../../core/entities/relibirds';
import {
  randomName,
  randomNameList,
  splitWords,
} from '../../../../../../../utils';
import DataContext from '../../../../../../../context';

const filter = require('leo-profanity');

const generateAnonName = () => {
  // generateAnonName generates a random username so that the leaderboard can track users who choose not to give their name - otherwise "Unknown Player" was all combined
  return `${randomName()}`;
};

const ReliBirdsGame: React.FC = () => {
  const { sessionCount } = useContext(DataContext);
  const [playerName, setPlayerName] = useState(
    localStorage.getItem('playerName') || '',
  );

  const [score, latestScore, highScore, gameStatus] = useAgile([
    relibirds.SCORE,
    relibirds.LATEST_SCORE,
    relibirds.HIGH_SCORE,
    relibirds.STATUS,
  ]);
  // const trackEvent = useEventTracker('Lab - ReliBirdsGame Section');
  const theme = useTheme();

  // useEffect(() => {
  //   if (!playerName || randomNameList.includes(playerName.replace('', ''))) {
  //     const anonName = generateAnonName();
  //     localStorage.setItem('playerName', anonName);
  //     setPlayerName(anonName);
  //   }
  // }, []);

  const updatePlayerName = (name) => {
    // catch edge cases where words are split by splitting the name to words that are in camel or pascal case
    const badWords = filter.badWordsUsed(splitWords(name));

    if (badWords.length === 0) {
      setPlayerName(name);
      localStorage.setItem('playerName', name);
    } else {
      // eslint-disable-next-line no-alert
      window.alert('Bad words disallowed!');
      const anonName = generateAnonName();
      localStorage.setItem('playerName', anonName);
      setPlayerName(anonName);
    }
  };

  const handleClickToStart = (e) => {
    if (!playerName) {
      e.stopPropagation();  // Prevent the event from propagating further
      e.preventDefault();   // Prevent the default action
      const newPlayerName = window.prompt("Please enter a unique user name to play.\n\nAs this field will be publicly visible, New Relic recommends using an alias here instead of your own name/username.\n");
      if (newPlayerName) {
        updatePlayerName(newPlayerName);
      }
    } else {
      // Proceed with starting the game
    }
  };

  return (
    <Container>
      <HeaderContainer
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Title>Let&apos;s Fly</Title>
        <Subtitle>With &quot;Reli-Birds&quot;</Subtitle>
        <br />

        {/* Centered prompt text above the input box */}
        <div
          style={{
            textAlign: 'center',
            color: 'rgb(255, 65, 39)',
            fontSize: '1.7rem',
            margin: '20px 0', // Adds vertical space above and below the prompt,
            fontFamily:
              'IBMPlexMono-Regular, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
          }}
        >
          Enter your unique alias to be on the LEADERBOARD <br /> 👇 👇
        </div>

        {/* Centered input box */}
        <input
          spellCheck={false}
          onChange={(e) => updatePlayerName(e.target.value)}
          style={{
            textAlign: 'center',
            fontSize: '1rem',
            background: 'transparent',
            color: 'rgb(87, 193, 95)',
            fontFamily:
              'IBMPlexMono-Regular, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            border: '2px solid rgb(87, 193, 95)', // Adds a green border to the input
            borderRadius: '4px', // Rounds the corners of the input box
            padding: '10px', // Adds space inside the input box
            outline: 'none', // Removes the default focus outline
            width: '60%', // Sets the width of the input box relative to its parent
            maxWidth: '300px', // Ensures the input box isn't too wide on large screens
          }}
          type="text"
          id="pname"
          name="pname"
          placeholder="ENTER ALIAS" // Placeholder text
          value={playerName}
        />
      </HeaderContainer>

      <ContentContainer>
        <InfoContainer>
          <StatsContainer linesCount={0} opacity={0.3}>
            <LabelText label="Score: " value={score.toString()} />
            <LabelText label="Top Score: " value={latestScore.toString()} />
            <LabelText label="High Score: " value={highScore.toString()} />
            <hr />
            <LabelText
              label="Online Players: "
              value={sessionCount.toString()}
            />
          </StatsContainer>

          {/* {highScore > 5 && (
            <ShareScoreButton
              leftIcon={Icon.Twitter}
              to={relibirds.getScoreTweetUri(highScore)}
              target="_blank"
              // Analytics
              onClick={() =>
                trackEvent({
                  action: 'share-score',
                  label: `Shared RELI-BIRDS Score`,
                })
              }
            >
              Share Score
            </ShareScoreButton>
          )} */}
        </InfoContainer>

        <GameContainer onClick={handleClickToStart}>
          <Game linesCount={20}>
            <ReliBirds />
          </Game>
          <ClickToStartContainer hide={gameStatus !== GAME_STATUS.SPLASH}>
            <Icon.Click width={30} height={30} color={theme.colors.layout.hc} />
            <ClickToStartText>Click to Start</ClickToStartText>
          </ClickToStartContainer>
        </GameContainer>
      </ContentContainer>
    </Container>
  );
};

export default ReliBirdsGame;

const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 70%;

  @media (max-width: ${ui.WIDTH_BREAK_POINTS[1]}px) {
    align-items: center;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${ui.WIDTH_BREAK_POINTS[1]}px) {
    align-items: center;
  }
`;

const Title = styled.h1`
  margin: 0 0 10px 0;

  color: ${({ theme }) => theme.colors.layout.p};
  font-size: 4rem;
  font-family: ${({ theme }) => theme.headings.fontFamily};
  white-space: nowrap;
  text-transform: uppercase;
  text-align: center;

  transition: font-size ${({ theme }) => theme.transitionTimingFunction} 500ms;

  text-shadow: 0 0 50px
    ${({ theme }) => ui.hexToRgba(theme.colors.layout.p, 0.5)};

  @media (max-width: ${ui.WIDTH_BREAK_POINTS[1]}px) {
    font-size: 3rem;
    white-space: pre-wrap;
  }

  @media (max-width: ${ui.WIDTH_BREAK_POINTS[0]}px) {
    font-size: 2.5rem;
  }
`;

const Subtitle = styled.h3`
  margin: 0;

  color: ${({ theme }) => theme.colors.interactive.primary.n0};
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.fontFamily};
  white-space: nowrap;
  text-transform: uppercase;

  @media (max-width: ${ui.WIDTH_BREAK_POINTS[1]}px) {
    font-size: 1rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 75%;

  margin-top: 50px;

  @media (max-width: ${ui.WIDTH_BREAK_POINTS[1]}px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  }
`;

const Game = styled(LinesBackground)`
  @media (max-width: ${ui.WIDTH_BREAK_POINTS[0]}px) {
    padding: 5px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${ui.WIDTH_BREAK_POINTS[1]}px) {
    margin-top: 30px;
  }
`;

const StatsContainer = styled(LinesBackground)`
  padding: 20px 60px;
`;

const ShareScoreButton = styled(Button)`
  display: flex;

  margin-top: 50px;
`;

const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ClickToStartContainer = styled.div<{ hide: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 20px;

  opacity: ${({ hide }) => (hide ? 0 : 1)};

  transition: opacity ${({ theme }) => theme.transitionTimingFunction} 500ms;
  animation: pulse 1s linear infinite;

  @keyframes pulse {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }
`;

const ClickToStartText = styled.p`
  margin: 0 0 0 10px;

  color: ${({ theme }) => theme.colors.layout.hc};
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fontFamily};
  text-align: center;
`;
