import { relibirds } from '../../../../core';
import { trackEvent } from '../../../hooks/useEventTracker';
import { GAME_STATUS } from '../../../../core/entities/relibirds';

export const inputHandler = () => {
  const cooldown = relibirds.COOLDOWN.value;
  const status = relibirds.STATUS.value;

  if (!cooldown) {
    switch (status) {
      case GAME_STATUS.SPLASH:
        console.log('START GAME');

        relibirds.startGame();
        relibirds.jumpBird();

        // Analytics
        trackEvent({
          category: 'RELI-BIRDS',
          action: 'start-game',
          label: 'Start Game',
        });

        break;
      case GAME_STATUS.PLAYING:
        console.log('JUMP');
        relibirds.jumpBird();
        break;
      case GAME_STATUS.SCORE:
        console.log('RESTART');

        relibirds.resetGame();

        // Analytics
        trackEvent({
          category: 'RELI-BIRDS',
          action: 'score',
          label: `Scored: ${relibirds.SCORE.value}`,
        });

        break;
      default:
        break;
    }
  }
};

export const toggledDeveloperMode = () => {
  relibirds.SHOW_COLLIDERS.set((v) => !v);
  relibirds.SHOW_PERFORMANCE.set((v) => !v);
};
