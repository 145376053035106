export default {
  title: 'Reli-Birds',
  image: '/img/meta.png',
  description: 'Let&apos;s Observe Flight Together',
  color: '#9FEAA4',
  keywords: ['Reli-Birds'],
  creator: '@kav91 & @aronmarden',
  url: 'http://aronmarden.com',
  baseUrl: '/',
};
