export const randomName = () => {
  return randomNameList[Math.floor(Math.random() * randomNameList.length)];
};

export const randomNameList = [
  'OneCommitCharles',
  'DropTableDan',
  'NullPointerNed',
  'ForkBombFrank',
  'SegmentationFaultSam',
  'MemoryLeakMike',
  'GigaByteGary',
  'PeskyPeterPython',
  'RegretfulRegexReece',
  'AnthonyAsyncAntics',
  'RogueRegexRoss',
  'JamesJankyCode',
  'JumbledJasonJSON',
  'SlowJavaJay',
  'RuntimeErrorRoss',
  'AngryAsyncAnthony',
  'GitGoneNoob',
  'RecursiveRob',
  'KernelPanicKevin',
  'DockerKillDuncan',
];

export const splitWords = (s) => {
  // eslint-disable-next-line
  let re,
    match,
    // eslint-disable-next-line
    output = [];
  // re = /[A-Z]?[a-z]+/g
  // eslint-disable-next-line
  re = /([A-Za-z]?)([a-z]+)/g;

  /*
	matches example: "oneTwoThree"
	["one", "o", "ne"]
	["Two", "T", "wo"]
	["Three", "T", "hree"]
	*/

  match = re.exec(s);
  while (match) {
    // output.push(match.join(""));
    output.push([match[1].toUpperCase(), match[2]].join(''));
    match = re.exec(s);
  }

  return output.join(' ');
};
