import React, { useState, useContext, useMemo } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import TableItem from './components/TableItem';
import TableOutline from './components/TableOutline';
import TableHeader from './components/TableHeader';
import InfoBox from '../InfoBox';
import { ui } from '../../../../core';
import { HighScoreItem } from '../../../../core/entities/games';
import { useWindowSize } from '../../../hooks/useWindowSize';
import DataContext from '../../../../context';

const HighScoreTable: React.FC<any> = (props) => {
  const [timeZones, setTimeZones] = useState(['Global'] as any);
  const { windowWidth } = useWindowSize();
  const [search, setSearch] = useState('');
  const { results, sortColumn } = useContext(DataContext);

  const sortData = (data) => {
    let targetKey = sortColumn;
    if (sortColumn === 'user') {
      targetKey = 'value';
    }

    return data.sort((a, b) => {
      if (['user', 'value', 'timeZone'].includes(targetKey)) {
        const nameA = (a[targetKey] || '').toUpperCase();
        const nameB = (b[targetKey] || '').toUpperCase();
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
      }

      return (b[targetKey] || 0) - (a[targetKey] || 0);
    });
  };

  return useMemo(() => {
    return (
      <Container>
        {results !== null && results.length > 0 && (
          <input
            spellCheck={false}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              fontSize: '1rem',
              background: 'transparent',
              color: 'rgb(87, 193, 95)',
              fontFamily:
                'IBMPlexMono-Regular, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
              border: '2px solid rgb(87, 193, 95)', // Adds a green border to the input
              borderRadius: '4px', // Rounds the corners of the input box
              padding: '10px', // Adds space inside the input box
              outline: 'none', // Removes the default focus outline
              width: '100%', // Sets the width of the input box relative to its parent
            }}
            type="text"
            id="searchLeaderboard"
            name="searchLeaderboard"
            placeholder="Search..." // Placeholder text
            value={search}
          />
        )}
        <InnerContainer>
          {results === null && <StyledInfoBox text="Loading scores..." />}
          {results !== null && results.length === 0 ? (
            <StyledInfoBox text="Recently no high scores were scored!" />
          ) : (
            <>
              <TableOutline />

              <StyledTable>
                {/* Table Content */}
                <thead>
                  <TableHeader sortColumn={sortColumn} />
                </thead>

                <tbody>
                  {sortData(results || [])
                    .filter(
                      (r) =>
                        !search ||
                        (r?.value || '')
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        (r?.timeZone || '')
                          .toLowerCase()
                          .includes(search.toLowerCase()),
                    )
                    .map((itemData, i) => (
                      <TableItem
                        key={i}
                        rank={i}
                        name={itemData.value}
                        discriminator={itemData.discriminator}
                        score={itemData.score}
                        playedAt={itemData.playedAt}
                        avatarUri={itemData.avatarUri}
                        timeZone={itemData.timeZone}
                      />
                    ))}
                  {windowWidth > ui.WIDTH_BREAK_POINTS[0] && (
                    <SpacerTableRow> </SpacerTableRow>
                  )}
                </tbody>
              </StyledTable>
            </>
          )}
        </InnerContainer>
      </Container>
    );
  }, [results, search]);
};

export default HighScoreTable;

type Props = {};

const Container = styled.div`
  position: relative;
`;

const InnerContainer = styled.div`
  min-height: 600px;
  max-height: 700px;

  overflow-x: auto; // https://www.w3schools.com/howto/howto_css_table_responsive.asp
  overflow-y: auto;

  // Hide Scrollbar
  // https://www.w3schools.com/howto/howto_css_hide_scrollbars.asp
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
`;

const StyledTable = styled.table`
  width: 100%;
  min-width: 400px;
`;

const StyledInfoBox = styled(InfoBox)`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: ${ui.WIDTH_BREAK_POINTS[1]}px) {
    top: 45%;
  }

  @media (max-width: ${ui.WIDTH_BREAK_POINTS[0]}px) {
    top: 50%;
  }
`;

const SpacerTableRow = styled.tr`
  position: relative;
  width: 100%;
  height: 200px;
`;
