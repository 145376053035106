import React from 'react';
// import { useAgile } from '@agile-ts/react';
import styled from 'styled-components';
import { ui } from '../../../../../../../core';

// import { memreli, ui } from '../../../../../../../core';
// import LinesBackground from '../../../../../../components/primitive/background/LinesBackground';
// import { useEventTracker } from '../../../../../../hooks/useEventTracker';
// import { Button } from '../../../../../../components/primitive';

const MemoryGame: React.FC = () => {
  // const [highScore, moves, timePlayed, maxTime, latestScore] = useAgile([
  //   memreli.HIGH_SCORE,
  //   memreli.MOVES_COUNT,
  //   memreli.TIME_PLAYED,
  //   memreli.MAX_TIME,
  //   memreli.LATEST_SCORE,
  // ]);
  // const trackEvent = useEventTracker('Lab - MemReliGame Section');

  return (
    <Container>
      <HeaderContainer>
        <Title>Server Performance</Title>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <Subtitle>monitored by New Relic Infra Agent</Subtitle>
      </HeaderContainer>

      <ChartContainer style={{ overflow: "hidden", height: "100%" }}>
        <iframe
          scrolling="no"
          src="https://chart-embed.service.newrelic.com/herald/2e0495ef-78ef-4a87-8382-9c539fc6c034?height=400px&timepicker=false"
          style={{
            width: '100%',
            height: "430px",
            border: '5px solid #a83ea0',
            filter: 'invert(1)', // This inverts the colors
            overflow: 'hidden' // Hide overflow to prevent scrollbars
          }}
          title="New Relic Chart"
        />&nbsp;
        <iframe
          scrolling="no"
          src="https://chart-embed.service.newrelic.com/herald/6015a8fe-7c3e-42b2-9e64-23fd81b3fb21?height=400px&timepicker=false"
          style={{
            width: '100%',
            height: "430px",
            border: '5px solid #a83ea0',
            filter: 'invert(1)', // This inverts the colors
            overflow: 'hidden' // Hide overflow to prevent scrollbars
          }}
          title="New Relic Chart"
        />
                <iframe
          scrolling="no"
          src="https://chart-embed.service.newrelic.com/herald/9eeac0d2-7a95-4e6a-9c11-06f1e19f127e?height=400px&timepicker=false"
          style={{
            width: '100%',
            height: "430px",
            border: '5px solid #a83ea0',
            filter: 'invert(1)', // This inverts the colors
            overflow: 'hidden' // Hide overflow to prevent scrollbars
          }}
          title="New Relic Chart"
        />
      </ChartContainer>
    </Container>
  );
};

export default MemoryGame;

const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;

  @media (max-width: ${ui.WIDTH_BREAK_POINTS[1]}px) {
    align-items: center;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${ui.WIDTH_BREAK_POINTS[1]}px) {
    align-items: center;
  }
`;

const Title = styled.h1`
  margin: 0 0 10px 0;

  color: ${({ theme }) => theme.colors.layout.p};
  font-size: 4rem;
  font-family: ${({ theme }) => theme.headings.fontFamily};
  white-space: nowrap;
  text-transform: uppercase;
  text-align: center;

  transition: font-size ${({ theme }) => theme.transitionTimingFunction} 500ms;

  text-shadow: 0 0 50px
    ${({ theme }) => ui.hexToRgba(theme.colors.layout.p, 0.5)};

  @media (max-width: ${ui.WIDTH_BREAK_POINTS[1]}px) {
    font-size: 3rem;
    white-space: pre-wrap;
  }

  @media (max-width: ${ui.WIDTH_BREAK_POINTS[0]}px) {
    font-size: 2.5rem;
  }
`;

const Subtitle = styled.h3`
  margin: 0;

  color: ${({ theme }) => theme.colors.interactive.primary.n0};
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.fontFamily};
  white-space: nowrap;
  text-transform: uppercase;

  @media (max-width: ${ui.WIDTH_BREAK_POINTS[1]}px) {
    font-size: 1rem;
  }
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;

  margin-top: 50px;

  @media (max-width: ${ui.WIDTH_BREAK_POINTS[1]}px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    height: 430px;
  }
`;
