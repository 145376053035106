import React, { createContext, useEffect } from 'react';
import { useSetState, useInterval } from '@mantine/hooks';
import { v4 as uuidv4 } from 'uuid';

const DataContext = createContext();

export function ProvideData(v) {
  const auth = useProvideData(v.platformContext);
  return <DataContext.Provider value={auth}>{v.children}</DataContext.Provider>;
}

export default DataContext;

export function useProvideData(props) {
  const getInitialState = () => {
    try {
      return {
        gameSessionId: localStorage.getItem('gameSessionId') || uuidv4(),
        appLaunchedAt:
          parseInt(localStorage.getItem('appLaunchedAt'), 10) ||
          new Date().getTime(),
        playerName: localStorage.getItem('playerName') || '',
        results: JSON.parse(localStorage.getItem('results')) || null,
        sessionCount: parseInt(localStorage.getItem('sessionCount'), 10) || 0,
        sortColumn: localStorage.getItem('sortColumn') || 'score',
      };
    } catch (error) {
      console.error(
        'Error retrieving initial state from local storage:',
        error,
      );
      return {
        gameSessionId: uuidv4(),
        appLaunchedAt: new Date().getTime(),
        playerName: '',
        results: null,
        sessionCount: 0,
        sortColumn: 'score',
      };
    }
  };

  const [dataState, setDataState] = useSetState(getInitialState());
  const resultsInterval = useInterval(() => getResults('polling'), 5000);

  // handle initial load
  useEffect(() => {
    const initialize = async () => {
      const appLaunchedAt = new Date().getTime();
      localStorage.setItem('appLaunchedAt', appLaunchedAt.toString());
      setDataState({ appLaunchedAt });

      getResults('polling');
    };
    initialize();
  }, []);

  useEffect(() => {
    resultsInterval.start();
    return resultsInterval.stop;
  }, [dataState?.sortColumn]);

  // Set up the click event listener
  useEffect(() => {
    let previousLiveScore =
      parseInt(localStorage.getItem('liveScore'), 10) || 0;

    const handleClick = async () => {
      const currentLiveScore =
        parseInt(localStorage.getItem('liveScore'), 10) || 0;
      console.log('Previous liveScore:', previousLiveScore);
      console.log('Current liveScore:', currentLiveScore);

      if (currentLiveScore === 0 && previousLiveScore !== 0) {
        console.log('Resetting tracking due to liveScore reset');
        await resetTracking();
        previousLiveScore = 0; // Reset previousLiveScore after tracking reset
      } else if (currentLiveScore === previousLiveScore + 1) {
        console.log('Triggering getResults due to liveScore increment');
        localStorage.setItem('liveScoreTime', new Date().getTime().toString()); // Set liveScoreTime
        getResults('tracking');
        previousLiveScore = currentLiveScore; // Update the previous live score
      }
    };

    // Add event listener for click events
    document.addEventListener('click', handleClick);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const resetTracking = async () => {
    const gameSessionId = uuidv4();
    localStorage.setItem('gameSessionId', gameSessionId);
    setDataState({
      gameSessionId,
      appLaunchedAt: new Date().getTime(),
      playerName: '',
      results: null,
      sessionCount: 0,
      sortColumn: 'score',
    });
    await getResults('polling');
  };

  const getResults = (triggerValue) => {
    return new Promise(async (resolve) => {
      const updateState = {};
      const gameSessionId = localStorage.getItem('gameSessionId') || '';
      const playerName = localStorage.getItem('playerName') || '';
      const liveScore = localStorage.getItem('liveScore') || '';
      const liveScoreTime = localStorage.getItem('liveScoreTime') || '';
      const host = window.location.hostname.includes(
        'relibirds.newrelic-es.com',
      )
        ? 'api.relibirds.newrelic-es.com'
        : `${window.location.hostname}:3333`;

      const url = `${window.location.protocol}//${host}/leaderboard?id=${gameSessionId}&playerName=${playerName}&liveScore=${liveScore}&liveScoreTime=${liveScoreTime}&sortColumn=${dataState.sortColumn}&trigger=${triggerValue}`;
      console.log('Fetching results with URL:', url);
      const results = await fetch(url);
      const json = await results.json();

      if (json?.sessionCount) {
        updateState.sessionCount = json.sessionCount;
      }

      updateState.results = json.results;

      setDataState(updateState);
      resolve(json.results);
    });
  };

  return {
    ...dataState,
    setDataState,
  };
}
