import { createState } from '@agile-ts/core';
import { ThemeTypes } from './themes';

export const THEME_TYPE = createState<ThemeTypes>('dark').persist({
  key: 'theme',
});
export const INITIALIZED_GA = createState(false);
export const SLOW_PERFORMANCE = createState(false).persist({
  key: 'slow-performance',
});

export const MAX_WIDTH = 1500;
export const INNER_WIDTH = 90; // %
export const NAVBAR_HEIGHT = 100;
export const WIDTH_BREAK_POINTS = [480, 768, 996, MAX_WIDTH];

export const API_URL = '';
