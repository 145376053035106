import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GAListener from '../ui/components/other/GAListener';
import config from '../config';

// Screens
import GameLabScreen from '../ui/pages/gamelab';
import FallbackScreen from '../ui/pages/404';
import TermsOfUseScreen from '../ui/pages/termsofuse';

const AppRouter: React.FC = () => (
  <BrowserRouter>
    <GAListener trackingId={config.google.gaTrackingCode as any}>
      <Routes>
        <Route path="/" element={<GameLabScreen />} />
        <Route path="/lab" element={<GameLabScreen />} />
        <Route path="/termsofuse" element={<TermsOfUseScreen />} />
        <Route path="*" element={<FallbackScreen />} />
      </Routes>
    </GAListener>
  </BrowserRouter>
);

export default AppRouter;
