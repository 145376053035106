export enum GAME_TYPES {
  reliBirds = 'relibirds',
  memReli = 'memreli',
  unknown = 'unknown',
}

export type HighScoreItem = {
  rank: number;
  name: string;
  discriminator: string;
  avatarUri: string;
  score: number;
  playedAt: Date;
};

export type RecentHighScoresResponse = HighScoreItem[];
