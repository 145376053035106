import { v4 as uuidv4 } from 'uuid';

import { fg_h, pipe_h, pipe_w } from './sprites';
import { PipeSet } from './elements';
import {
  BACKGROUND_WRAPPER,
  BIRD,
  DEFAULT_BIRD_POSITION,
  BIRD_SKIN,
  COOLDOWN,
  FOREGROUND_WRAPPER,
  GAME,
  HIGH_SCORE,
  LATEST_SCORE,
  PIPE_SETS,
  SCORE,
  STATUS,
  GAME_TYPE,
} from './game.controller';
import { GAME_STATUS } from './game.types';
import { CURRENT_USER } from '../user';
import { sendHighScore } from '../games';
import { getEncryptedJsonPayload } from '../../utils/crypto';

export const startGame = () => {
  localStorage.setItem('gameSessionId', uuidv4());
  localStorage.setItem('gameStartTime', new Date().getTime().toString());
  localStorage.setItem('liveScore', '0'); // Reset live score at the start of the game
  localStorage.setItem('liveScoreTime', new Date().getTime().toString()); // Set liveScoreTime
  STATUS.set(GAME_STATUS.PLAYING);
};

export const endGame = () => {
  if (STATUS.value === GAME_STATUS.PLAYING) {
    // Set game end time
    localStorage.setItem('gameEndTime', new Date().getTime().toString());

    // Cooldown for no instant restart
    COOLDOWN.set(true);
    setTimeout(() => {
      COOLDOWN.set(false);
    }, 1000);

    STATUS.set(GAME_STATUS.SCORE);

    // Calculate High Score
    if (HIGH_SCORE.value < SCORE.value) {
      HIGH_SCORE.set(SCORE.value);
    }

    // Send score to backend if a user is authenticated
    if (CURRENT_USER.value != null) sendHighScore(SCORE.value, GAME_TYPE);

    const playerName = localStorage.getItem('playerName');
    const sessionId = localStorage.getItem('sessionId');
    const gameSessionId = localStorage.getItem('gameSessionId');
    const gameStartTime = localStorage.getItem('gameStartTime') || '';
    const gameEndTime = localStorage.getItem('gameEndTime') || '';
    const appLaunchedAt = localStorage.getItem('appLaunchedAt') || '';

    const host = window.location.hostname.includes('relibirds.newrelic-es.com')
      ? 'api.relibirds.newrelic-es.com'
      : `${window.location.hostname}:3333`;

    const url = `${window.location.protocol}//${host}/saveScore`;

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const event = urlParams.get('event');

    const payload = {
      score: SCORE.value,
      name: playerName || 'Unknown Player',
      sessionId: sessionId || 'unknown',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timePlayedAt: new Date().getTime(),
      gameSessionId,
      gameStartTime: Number(gameStartTime),
      gameEndTime: Number(gameEndTime),
      event,
      appLaunchedAt: Number(appLaunchedAt),
    };

    const encryptedPayload = getEncryptedJsonPayload(payload);

    const tempWindow: any = window;
    // eslint-disable-next-line prefer-destructuring
    const newrelic = tempWindow.newrelic;
    newrelic.addPageAction('saveScore', {
      playerName,
      score: SCORE.value,
      sessionId,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      gameSessionId,
      gameStartTime: Number(gameStartTime),
      gameEndTime: Number(gameEndTime),
      event,
      appLaunchedAt: Number(appLaunchedAt),
    });

    newrelic.setUserId(playerName);

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...encryptedPayload }),
    });

    LATEST_SCORE.set(SCORE.value);
    SCORE.reset();
  }
};

export const resetGame = () => {
  // Reset Bird
  const bird = BIRD.nextStateValue;
  bird.unlockRotation();
  bird.move({ cy: DEFAULT_BIRD_POSITION.y, cx: DEFAULT_BIRD_POSITION.x });
  bird.rotate(0);
  BIRD.ingest();

  // Reset Pipes
  PIPE_SETS.reset();

  // Reset Game
  STATUS.set(GAME_STATUS.SPLASH);

  // Reset live score
  localStorage.setItem('liveScore', '0');
  localStorage.setItem('liveScoreTime', new Date().getTime().toString()); // Set liveScoreTime

  // Update Skins
  BIRD_SKIN.set(Math.floor(Math.random() * 20) + 1);
};

export const updateFrame = () => {
  GAME.update();

  // Update Scenery
  updateScenery();

  // Update Bird
  updateBird();
};

export const renderFrame = (lagOffset: number) => {
  BIRD.value.render(lagOffset);
  FOREGROUND_WRAPPER.value.render(lagOffset);
  BACKGROUND_WRAPPER.value.render(lagOffset);
  PIPE_SETS.value.forEach((pipeSet) => pipeSet.render(lagOffset));
};

export const updateBird = () => {
  const bird = BIRD.nextStateValue;
  const status = STATUS.value;

  const { canvasDimensions } = GAME;
  const { foregrounds } = FOREGROUND_WRAPPER.value;

  // If Splash Screen make the Bird hover
  if (status === GAME_STATUS.SPLASH) {
    const hoverHeight = 280;
    bird.hover(canvasDimensions.height - hoverHeight);
  }

  if (
    status === GAME_STATUS.PLAYING ||
    status === GAME_STATUS.SCORE // Apply physics to the Bird also in the Score status, to drop the bird when hitting a Pipe
  ) {
    bird.update();

    const collisionWithGround =
      bird.calculateCollision(foregrounds[0]) ||
      bird.calculateCollision(foregrounds[1]);

    // Handle collision with ground
    if (collisionWithGround) {
      bird.lockRotation();
      if (bird.cy >= canvasDimensions.height - fg_h - 10)
        bird.move({ cy: canvasDimensions.height - fg_h - 10 });

      endGame();
    }

    // Handle collision with top
    if (
      bird.calculateCollision({
        cx: 0,
        cy: 0,
        width: canvasDimensions.width,
        height: 10,
      })
    ) {
      if (bird.cy <= 0) bird.move({ cy: 0 });

      // Bounce
      bird.setVelocity(2);
    }
  }
};

export const jumpBird = () => {
  const bird = BIRD.nextStateValue;
  bird.jump();

  // Retrieve playerName from localStorage
  const playerName = localStorage.getItem('playerName');
  const sessionId = localStorage.getItem('sessionId');
  const gameSessionId = localStorage.getItem('gameSessionId');
  const gameStartTime = localStorage.getItem('gameStartTime') || '';
  const gameEndTime = localStorage.getItem('gameEndTime') || '';
  const appLaunchedAt = localStorage.getItem('appLaunchedAt') || '';

  const host = window.location.hostname.includes('relibirds.newrelic-es.com')
    ? 'api.relibirds.newrelic-es.com'
    : `${window.location.hostname}:3333`;

  const url = `${window.location.protocol}//${host}/saveScore`;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const event = urlParams.get('event');

  // Report to New Relic
  const tempWindow: any = window;
  const { newrelic } = tempWindow;
  if (newrelic && typeof newrelic.addPageAction === 'function') {
    newrelic.addPageAction('jumpBird', {
      result: 'success',
      playerName,
      sessionId,
      gameSessionId,
      gameStartTime,
      gameEndTime,
      appLaunchedAt,
      url,
      event,
      birdPosition: { x: bird.cx, y: bird.cy },
      score: SCORE.value,
    });
  }
};

export const updateScenery = () => {
  const status = STATUS.value;

  if (status === GAME_STATUS.SPLASH || status === GAME_STATUS.PLAYING) {
    // Update Background and Foreground
    BACKGROUND_WRAPPER.nextStateValue.update();
    FOREGROUND_WRAPPER.nextStateValue.update();

    // Update Pipes
    if (status === GAME_STATUS.PLAYING) updatePipes();
  }
};

export const updatePipes = () => {
  let pipeSets = PIPE_SETS.value;
  const bird = BIRD.value;

  // Generate Pipe Sets
  if (
    pipeSets.length === 0 ||
    pipeSets[pipeSets.length - 1].movedCx >=
      pipeSets[pipeSets.length - 1].distance
  ) {
    pipeSets = pipeSets.concat(
      new PipeSet(GAME, {
        cx: pipe_h,
        cy: 0,
        distance: 200,
        gap: 120,
        renderCallback: () => {
          PIPE_SETS.ingest();
        },
      }),
    );
  }

  pipeSets.forEach((pipeSet) => {
    pipeSet.update();

    // End Game, if Bird collides with Pipe
    if (
      pipeSet.topPipe.calculateCollision(bird) ||
      pipeSet.bottomPipe.calculateCollision(bird)
    )
      endGame();

    // Calculate Score
    if (pipeSet.calculateCollision(bird) && !pipeSet.scored) {
      SCORE.set((v) => {
        const newScore = v + 1;
        localStorage.setItem('liveScore', newScore.toString());
        localStorage.setItem('liveScoreTime', new Date().getTime().toString()); // Set liveScoreTime
        return newScore;
      });
      pipeSet.scored = true;
    }

    // Remove pipes that go out of view
    if (pipeSet.cx < -pipe_w) pipeSets.splice(0, 1);

    return pipeSet;
  });

  // Apply changes to the UI
  PIPE_SETS.set(pipeSets);
};

export const getScoreTweetUri = (score: number) =>
  `https://twitter.com/intent/tweet?text=I%20just%20played%20reli-birds%20and%20managed%20to%20score%20${score}%21%20Can%20you%20beat%20me%3F%20Try%20it%20here%20https%3A//relibirds.newrelic-es.com/`;
