import React from 'react';
import Spacer from '../../../../components/other/Spacer';
import MemReliGame from './components/MemReliGame';
import RecentHighScores from '../RecentHighScores';
import { GAME_TYPES } from '../../../../../core/entities/games';

const ReliBirdsView: React.FC = () => (
  <>
    <MemReliGame />
    <Spacer height={200} />
    {/* <RecentHighScores gameType={GAME_TYPES.memReli} /> */}
  </>
);

export default ReliBirdsView;
