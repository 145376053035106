import React, { useMemo, useContext } from 'react';
import styled from 'styled-components';
import BottomDivider from './BottomDivider';
import { HORIZONTAL_PADDING } from '../controller';
import DataContext from '../../../../../context';

const TableHeader: React.FC<any> = (props) => {
  const { sortColumn, setDataState } = useContext(DataContext);

  const selectedStyle = {
    color: 'rgb(159, 234, 164)',
  };

  const unSelectedStyle = {
    cursor: 'pointer',
  };

  return useMemo(() => {
    return (
      <Container>
        <RankText>#</RankText>
        <UserText
          onClick={() => setDataState({ sortColumn: 'user' })}
          style={sortColumn === 'user' ? selectedStyle : unSelectedStyle}
        >
          User {sortColumn === 'user' && <>&#709;</>}
        </UserText>
        <Cell
          onClick={() => setDataState({ sortColumn: 'score' })}
          style={sortColumn === 'score' ? selectedStyle : unSelectedStyle}
        >
          Score {sortColumn === 'score' && <>&#709;</>}
        </Cell>
        <Cell
          onClick={() => setDataState({ sortColumn: 'playedAt' })}
          style={sortColumn === 'playedAt' ? selectedStyle : unSelectedStyle}
        >
          Time Ago {sortColumn === 'playedAt' && <>&#709;</>}
        </Cell>
        <Cell
          onClick={() => setDataState({ sortColumn: 'timeZone' })}
          style={sortColumn === 'timeZone' ? selectedStyle : unSelectedStyle}
        >
          Time Zone {sortColumn === 'timeZone' && <>&#709;</>}
        </Cell>
        <BottomDivider />
      </Container>
    );
  }, [sortColumn]);
};

export default TableHeader;

const Container = styled.tr`
  position: relative;
`;

const Cell = styled.th`
  padding: 15px 0;

  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.layout.hc};
  text-align: left;
`;

const RankText = styled(Cell)`
  padding-left: ${HORIZONTAL_PADDING}px;
`;

const UserText = styled(Cell)`
  padding-left: 20px;
`;
