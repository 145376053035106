import React from 'react';
import ReliBirdsGame from './components/ReliBirdsGame';
import Spacer from '../../../../components/other/Spacer';
import RecentHighScores from '../RecentHighScores';
import { GAME_TYPES } from '../../../../../core/entities/games';

const ReliBirdsView: React.FC = () => (
  <>
    <ReliBirdsGame />
    <Spacer height={50} />
    <RecentHighScores gameType={GAME_TYPES.reliBirds} />
  </>
);

export default ReliBirdsView;
